import { addToCart } from '../utils/cart';
import dispatchEvent from '../utils/dispatch-event';

let handled = false;
let previousCartItems = window.cart_items;

addEventListener('cart:handle-promotions', () => {
  handlePromotions();
});

const isPromotionalItem = (id) => {
  const settings = window.buy_x_get_y_settings;
  if (!settings) {
    return false;
  }
  for (const promotion of settings) {
    if (promotion.x_id == id) {
      return true;
    }
  }
  return false;
};

const handlePromotions = (product_id = null, variant_id = null, product_cost = 0) => {
  const settings = window.buy_x_get_y_settings;
  const cart_items = window.cart_items;
  let giftsToAdd = [];

  //if a product_id is passed in we can push that item to the cart
  //this is used for the quick add to cart button and adding a product to the cart from the product page
  //if the product is incremented from the cart page, we don't want to add the gift again
  if (product_id) {
    giftsToAdd.push({ id: variant_id, quantity: 1 });
  }

  //if there are no settings or no cart items, we can return
  if (!settings || !cart_items) {
    return;
  }

  //if the cart items haven't changed, we can return
  let previous = groupItems(previousCartItems);
  let current = groupItems(cart_items);
  
  if(handled && !product_id){
    handled = noPromotionalItemsAdded(settings, current, previous);    
  }
  previousCartItems = [...window.cart_items];

  if (handled && !product_id) {
    hideDuplicateLineItemModifiers();
    return;
  }

  //loop through the settings for each promotion
  for (const promotion of settings) {

    //if there is no gift, we can continue
    if(promotion.gifts.length == 0){
      continue;
    }

    const gift = promotion.gifts[0];
    const promoSettings = {
      amount_of_gifts_to_add: 0,
      cart_items,
      gift,
      gift_quantity: 0,
      max_gifts_per_order: parseInt(promotion.max_gifts_per_order) || 0,
      minimum_spend: promotion.minimum_spend,
      product_cost,
      product_id,
      qualifying_item_cost: promotion.qualifying_item_cost,
      qualifying_item_quantity: 0,
      x_id: promotion.x_id,
      y_inventory_quantity: gift.inventory_quantity,
      y_variant_id: gift.id,
    };

    //if a product_id is passed in, we can check if it matches the x_id
    //if it does, we can increment the qualifying_item_quantity
    if(promoSettings.product_id != null){
      if(promoSettings.x_id == promoSettings.product_id){
        promoSettings.qualifying_item_quantity ++;
      }
    }

    //loop through the cart items to find the qualifying item
    //if the item has a selling plan, we can continue
    //if the item matches the x_id, we can increment the qualifying_item_quantity
    for (const item of promoSettings.cart_items) {
      if(item.selling_plan_allocation?.selling_plan != null){
        continue;
      }

      if (item.product_id == promoSettings.x_id || item.variant_id == promoSettings.x_id) {
        promoSettings.qualifying_item_quantity += item.quantity;
      }
    }

    //loop through the cart items to find the gift and increment the gift_quantity
    for (const item of promoSettings.cart_items) {
      if (item.variant_id == promoSettings.y_variant_id) {
        promoSettings.gift_quantity += item.quantity;
      }
    }

    //if the qualifying_item_quantity is greater than 0, we can handle the promotion as a buy x get y
    if (promoSettings.qualifying_item_quantity > 0) {
      handleBuyXGetYPromotion(promoSettings, giftsToAdd);
      continue;
    }

    //if the minimum_spend is greater than 0 and the x_id is empty, we can handle the promotion as a spend x get y
    if (promoSettings.minimum_spend > 0 && promoSettings.x_id == '') {
      handleSpendXGetYPromotion(promoSettings, giftsToAdd);
      continue;
    }

  }

  //if there are gifts to add, we can add them to the cart
  if (giftsToAdd.length > 0) {
    addToCart(
      {
        items: giftsToAdd,
      }
    ).then((response) => {
        dispatchEvent('cart:quantity-updated', document);
    });
  }

  //set handled to true so we don't add the gift again
  handled = true;

  //hide the duplicate line item modifiers
  hideDuplicateLineItemModifiers();
}

//if the minimum_spend is greater than 0 and the x_id is empty, we can handle the promotion as a spend x get y
//if the total_spent is greater than or equal to the minimum_spend, we can add the gift
//if the gift_quantity is less than 1 and the y_inventory_quantity is greater than 0, we can add the gift
function handleSpendXGetYPromotion(promoSettings, giftsToAdd) {
    const total_spent = promoSettings.cart_items.reduce((total, item) => {
        return total + (item.price * item.quantity);
    } , 0) + promoSettings.product_cost;

    for (const item of promoSettings.cart_items) {
        if (item.variant_id == promoSettings.y_variant_id) {
            promoSettings.gift_quantity += item.quantity;
        }
    }

    if(total_spent >= promoSettings.minimum_spend){
        if (promoSettings.gift_quantity < 1 && promoSettings.y_inventory_quantity > 0) {
            giftsToAdd.push({ id: promoSettings.y_variant_id, quantity: 1 });
        }
    }
  return {promoSettings, giftsToAdd};
}

//if the qualifying_item_quantity is greater than 0, we can handle the promotion as a buy x get y
//if the x_id is equal to the y_variant_id, we can add the gift_quantity to the amount_of_gifts_to_add
//if the qualifying_item_quantity is greater than the gift_quantity, we can calculate the amount_of_gifts_to_add
//if the max_gifts_per_order is greater than 0, we can calculate the amount_of_gifts_to_add
//if the amount_of_gifts_to_add is greater than 0, we can add the gift
function handleBuyXGetYPromotion(promoSettings, giftsToAdd) {

    if (promoSettings.x_id == promoSettings.y_variant_id) {
        promoSettings.amount_of_gifts_to_add = promoSettings.qualifying_item_quantity % 2;
    } else {
        if (promoSettings.qualifying_item_quantity > promoSettings.gift_quantity) {
            const spent_on_item = promoSettings.qualifying_item_cost * promoSettings.qualifying_item_quantity;
            promoSettings.amount_of_gifts_to_add = Math.floor((spent_on_item / promoSettings.minimum_spend) - promoSettings.gift_quantity);
        }
    }

    if (promoSettings.max_gifts_per_order > 0) {
        promoSettings.max_gifts_per_order *= (promoSettings.x_id == promoSettings.y_variant_id) ? 2 : 1;
        const total_gifts = promoSettings.amount_of_gifts_to_add + promoSettings.gift_quantity;
        promoSettings.amount_of_gifts_to_add = (total_gifts > promoSettings.max_gifts_per_order) ? ((promoSettings.x_id == promoSettings.y_variant_id) ? 0 : promoSettings.max_gifts_per_order - promoSettings.gift_quantity) : promoSettings.amount_of_gifts_to_add;
    }

    const newTotalGifts = promoSettings.amount_of_gifts_to_add + promoSettings.gift_quantity;
    if (newTotalGifts > promoSettings.y_inventory_quantity) {
        promoSettings.amount_of_gifts_to_add = promoSettings.y_inventory_quantity - promoSettings.gift_quantity;
    }

    if(promoSettings.amount_of_gifts_to_add > 0){
        giftsToAdd.push({ id: promoSettings.y_variant_id, quantity: promoSettings.amount_of_gifts_to_add });
    }

  return {promoSettings, giftsToAdd};
}

const hideDuplicateLineItemModifiers = () => {
    let duplicateItems = [];
    const cartItems = window.cart_items;

    for(let i = 0; i < cartItems.length; i++){
      let currentItem = cartItems[i];

      for(let j = i + 1; j < cartItems.length; j++){
        let nextItem = cartItems[j];
        
        if(currentItem.variant_id == nextItem.variant_id){
          if(currentItem.discounts.length > 0){
            duplicateItems.push(currentItem.key);
          }
          if(nextItem.discounts.length > 0){
            duplicateItems.push(nextItem.key);
          }
        }
      }
    }    

    duplicateItems.forEach((item) => {
     for (const itemDiv of document.querySelectorAll(`[data-line-item-key="${item}"]`)) {
        const decrementSpan = itemDiv.querySelector('[data-decrement]');
        const incrementSpan = itemDiv.querySelector('[data-increment]');
        const removeItem = itemDiv.querySelector('[data-remove-button]');
        decrementSpan.classList.add('hidden');
        incrementSpan.classList.add('hidden');
        removeItem.classList.add('hidden');
      }

    });

}

function groupItems(items) {
  let groupedItems = {};
  for (const item of items) {
    if (groupedItems[item.variant_id]) {
      groupedItems[item.variant_id].quantity += item.quantity;
    } else {
      groupedItems[item.variant_id] = { variant_id: item.variant_id, quantity: item.quantity };
    }
  }
  return groupedItems;
}

function noPromotionalItemsAdded(promotions, currentItems, previousItems) {
  if (Object.keys(previousItems).length === 0) {
    return false;
  }

  for (const promotion of promotions) {
    if (currentItems[promotion.x_id] && previousItems[promotion.x_id]) {
      if (previousItems[promotion.x_id].quantity == 1){
        return false;
      }
      if (currentItems[promotion.x_id].quantity > previousItems[promotion.x_id].quantity) {
        return false;
      }
    }

  }
  return true;
}

export { hideDuplicateLineItemModifiers, handlePromotions, isPromotionalItem };
